import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const buttonStyle = {
    background: "#22b378",
    color: "white",
    borderRadius: 2,
    textTransform: "none",
    fontWeight: 400,
    "&:hover": {
      background: "grey",
    },
  };
  
  export const title = {
    fontSize: { xl: "1rem", xs: "1.25rem" },
    fontWeight: 400,
  };
  
  export const contentStyle = {
    fontSize: { xl: "0.8rem", xs: "0.8rem" },
    fontWeight: 400,
    textTransform: "none",
};

export default function Confirmation({
  content,
  handleAgree,
  open,
  show,
}) {
  const handleClose = () => {
    show(!open);
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{borderRadius:5 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={title} id="alert-dialog-title">
          {"If you leave, remark will be marked as done"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={contentStyle}  id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{borderTop:'1px solid #e4e4e4'}}>
          <Button onClick={handleClose} sx={buttonStyle} autoFocus>
            Stay here
          </Button>
          <Button onClick={handleAgree} sx={{...buttonStyle,backgroundColor:'lightgrey',color:'black'}} >
            Leave & Mark as Done
          </Button>
          
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}