import { Box, IconButton, Typography } from "@mui/material";
import Sidebar from "./Sidebar";
import React, { useEffect, useState } from "react";
import { VideoSection } from "./VideoSection";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AssessmentSection from "./AssessmentSection";
import { getIntranetAssessment } from "../services/AssessmentService";
import { IntranetAssessmentCards } from "./IntranetAssessmentCards";

export const LnDTopics = React.memo(
  ({ selectedIntranet, setSelectedIntranet }) => {
    const sections = ["Video", "Assessment"];
    const [assessmentList, setAssessmentList] = useState([]);
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [selectedSection, setSelectedSection] = useState("Video");

    useEffect(() => {
      console.log(selectedAssessment);
    }, [selectedAssessment]);

    useEffect(() => {
      if (selectedSection === "Assessment") {
        (async () => {
          const response = await getIntranetAssessment(
            selectedIntranet._id,
            "Intranet"
          );
          console.log("response", response);
          setAssessmentList(response);
        })();
      }
    }, [selectedSection]);

    useEffect(() => {
      console.log("selectedIntranet", selectedIntranet);
    }, [selectedIntranet]);

    return (
      <>
        <div className="app-container">
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              background: "black",
              m: 1,
              mt: 2,
              height: "fit-content",
              width: "fit-content",
              "&:hover": { background: "black" },
            }}
            onClick={() => setSelectedIntranet("")}
          >
            <ArrowBackIosIcon
              fontSize="small"
              sx={{ margin: "auto", ml: 0.6, color: "#FFFFFF" }}
            />
          </IconButton>
          {selectedSection === "Video" && selectedIntranet?.Videos?.length && (
            <VideoSection
              ppt={selectedIntranet}
              items={selectedIntranet.Videos}
            />
          )}
          {selectedSection === "Assessment" && (
            <>
              {
                !selectedAssessment  ?
                <IntranetAssessmentCards module={selectedIntranet} assessmentList={assessmentList} setSelectedAssessment={setSelectedAssessment} /> :
                <AssessmentSection selectedModule={selectedAssessment} moduleType={"Intranet"}  />
                
              }
            </>
          )}
        </div>
        <div className="bottom-bar">
          {sections.map((section, index) => (
            <div
              key={index}
              className={
                section === selectedSection
                  ? "selected-section"
                  : "section-item"
              }
              onClick={() => setSelectedSection(section)}
            >
              <p>{section}</p>
            </div>
          ))}
        </div>
      </>
    );
  }
);
