import axios from "axios";
import { API_URL, getHeaders } from "../config";

export const getIntranets = (section, category) => {
  return axios.get(
    API_URL +
      `/api/intranet/user-intranets?section=${section}&category=${category}`,
    { headers: getHeaders().headers }
  );
};

export const submitComment = (text, id) => {
  return axios.post(
    API_URL + `/api/intranet/comment/create`,
    { text, id },
    { headers: getHeaders().headers }
  );
};

export const getComments = (id) => {
  return axios.get(API_URL + `/api/intranet/comments/${id}`, {
    headers: getHeaders().headers,
  });
};

export const likePost = (id) => {
  return axios.post(
    API_URL + `/api/intranet/like/create`,
    { id },
    { headers: getHeaders().headers }
  );
};

export const getLikes = (id) => {
  return axios.get(API_URL + `/api/intranet/likes/${id}`, {
    headers: getHeaders().headers,
  });
};

export const createCommentReply = (text, id) => {
  return axios.post(
    API_URL + `/api/intranet/comment/reply/create`,
    { text, id },
    { headers: getHeaders().headers }
  );
};

export const getCommentReplies = (id) => {
    return axios.get(
        API_URL + `/api/intranet/comment/replies/${id}`,
        { headers: getHeaders().headers }
    )
}

export const createCommentLike = (id) => {
    return axios.post(
        API_URL + `/api/intranet/comment/like/create`,
        { id },
        { headers: getHeaders().headers }
    )
}