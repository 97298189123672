import { Box, Grid2, Typography } from "@mui/material";
import AssessmentImg from "../assets/images/assessment.png";

export const IntranetAssessmentCards = ({ module, assessmentList, setSelectedAssessment }) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "scroll",
        background: "#FFFFFF",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ width: "100%", height: "60vh" }}>
        <div className="ppt-title">
            
        <h3
          style={{
            margin: "0px",
            marginTop: "10px",
            fontSize: "1.5rem",
          }}
        >
          {module?.title}
        </h3>
        </div>
        <Box sx={{ p: 5 }}>
          <Grid2 container>
            {assessmentList &&
              assessmentList.length > 0 &&
              assessmentList.map((item, index) => (
                <Grid2 size={{ md: 2 }}>
                  <Box
                    // className="intranet-item"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: "20px",
                      width: "100%",
                      height: "auto",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      transition: "transform 0.3s ease-in-out",
                      borderRadius: "12px",
                      background: "#ffffff",
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                      onClick={() => {
                        setSelectedAssessment(item)
                      }}
                    key={index}
                  >
                    <Box>
                      <Box sx={{ marginBottom: "15px" }}>
                        <img
                          src={AssessmentImg}
                          alt={item.title}
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                      </Box>
                      <Box sx={{ textAlign: "start", padding: "0 10px" }}>
                        <Typography
                          fontSize={20}
                          fontWeight="bold"
                          sx={{ marginBottom: "5px" }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "10px",
                            color: "grey",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 5, // Show 5 lines by default
                            maxHeight: "6em",
                          }}
                        >
                          {item.description}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "10px",
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          {/* <Typography>{item.name}</Typography> */}
                          <Typography>{item?.questions?.length} Questions</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid2>
              ))}
          </Grid2>
        </Box>
      </Box>
    </Box>
  );
};
