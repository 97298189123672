import { useEffect, useState } from "react";
import { API_URL, getHeaders } from "../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Worker } from '@react-pdf-viewer/core';

import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import "../assets/styles/scrollbar.css";

export const PDFViewer = ({ pdf, width, height }) => {  

  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };


  useEffect(() => {
    if(url) {
      console.log("url>>>>>>>>", url);
    }
  }, [url]);

    // useEffect(() => {
    //     if (pdf) {
    //       console.log("ppt---", pdf);
    //       const fetchSignedUrl = async () => {
    //         setIsLoading(true);
    //         try {
    //           const response = await axios.get(
    //             `${API_URL}/api/PPT/signed-url?key=${pdf}`,
    //             { headers: getHeaders().headers }
    //           );
    //           const signedUrl = response.data?.url;
    //           console.log("Signed URL fetched:", signedUrl);
    //           setUrl(signedUrl); // Set the signed URL
    //         } catch (e) {
    //           console.error("Error fetching signed URL", e);
    //           setIsLoading(false);
    //           const { status } = e?.response;
    //         if (!status) return;
    //         if (status === 401 || status === 403) {
    //           handleLogout();
    //         }
    //         }
    //       };
    
    //       fetchSignedUrl();
    //     }
    //   }, [pdf]);
    

    return (
        <>
           {
            pdf &&   
            <div className="scrollbar-container" style={{ height: height || '85vh', width: width || '100%', overflow: 'auto', border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer fileUrl={`${API_URL}/api/assets/PDF/${pdf}`} />
              </Worker>
            </div>
           }

        </>
    )
}