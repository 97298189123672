import React, { useState, useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";

function TimeDisplay({ totalSeconds }) {
  const [remainingTime, setRemainingTime] = useState(totalSeconds);

  useEffect(() => {
    const calculateTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return { hours, minutes, seconds: secs };
    };

    const interval = setInterval(() => {
      setRemainingTime((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const calculateTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return { hours, minutes, seconds: secs };
  };

  const time = calculateTime(remainingTime);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minWidth: "300px",
        padding: "16px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        background: "#2e64b8",
        color: "#fff",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
        Time Remaining
      </Typography>
      <Typography variant="h6" sx={{ marginTop: "8px" }}>
        {time.hours > 0 && `${time.hours} hour${time.hours !== 1 ? "s" : ""}, `}
        {time.minutes > 0 &&
          `${time.minutes} minute${time.minutes !== 1 ? "s" : ""}, `}
        {time.seconds} second{time.seconds !== 1 ? "s" : ""}
      </Typography>
    </Box>
  );
}

export default TimeDisplay;
