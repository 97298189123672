import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import IntranetImg from "../assets/images/IntranetTask.png";
import { useEffect, useState } from "react";
import {
  getComments,
  getIntranets,
  getLikes,
  likePost,
  submitComment,
} from "../services/IntranetService";
import { API_URL } from "../config";
import { PDFViewer } from "../components/PDFViewer";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import TextEditor from "../components/TextEditor";
import { CommentList } from "../components/CommentList";
import { Grid2 } from "@mui/material";
import "../assets/styles/scrollbar.css";
import Sidebar from "../components/Sidebar";
import User from "../assets/images/user.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import HLSPlayer from "../components/VideoPlayer";
import Confirmation from "../components/Confirmation";
import { DataSecurity, LnDTopics } from "../components/LnDTopics";

export const Intranet = () => {
  const [section, setSection] = useState("All");
  const [intranetList, setIntranetList] = useState([]);
  const [selectedIntranet, setSelectedIntranet] = useState("");
  const [isAdded, setAdded] = useState(false);
  const [isLiked, setLiked] = useState(false);
  const [comments, setComments] = useState([]);
  const [likes, setLikes] = useState(0);
  const [openCategory, setOpenCategory] = useState(0);
  const [selectedCategory, setCategory] = useState("Policies");
  const [isLeaving, setLeaving] = useState(false);

  const navigate = useNavigate();

  const sections = [
    {
      name: "All",
    },
    {
      name: "General",
    },
    {
      name: "My Department",
    },
  ];

  const categories = [
    {
      title: "Policies",
    },
    {
      title: "News",
    },
    {
      title: "Announcement",
    },
    {
      title: "Notification",
    },
    {
      title: "LnD"
    }
  ];

  useEffect(() => {
    if (selectedCategory) {
      console.log("selectedCategory:::", selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    (async () => {
      try {
        console.log("getting...", section, selectedCategory);
        const { data } = await getIntranets(section, selectedCategory);
        setIntranetList(data?.data);
      } catch (e) {
        const { status } = e?.response;
        if (!status) return;
        if (status === 401 || status === 403) {
          handleLogout();
        }
      }
    })();
  }, [section, selectedCategory]);

  useEffect(() => {
    if (selectedIntranet._id) {
      setAdded(true);
    }
  }, [selectedIntranet]);

  useEffect(() => {
    if (isAdded) {
      (async () => {
        const { data } = await getComments(selectedIntranet._id);
        const likes = await getLikes(selectedIntranet._id);
        setLikes(likes?.data?.likes);
        setLiked(likes?.data?.isLiked);
        setComments(data?.data);
        setAdded(false);
      })();
    }
  }, [isAdded]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleSubmit = async (text, id) => {
    console.log("Submitted text:", text);
    try {
      const { data } = await submitComment(text, id);
      if (data?.message) {
        toast.success("Comment posted successfully");
        setAdded(true);
      }
    } catch (e) {
      toast.error("An error occurred while posting your comment");
    }
  };

  const handleLike = async () => {
    setLiked(!isLiked);
    try {
      const { data } = await likePost(selectedIntranet._id);
      console.log("api called..");
      if (data?.message) {
        console.log("api success message..");
        setAdded(true);
        // toast.success("Liked");
      }
    } catch (e) {
      // setLiked()
    }
  };

  const handleDisLike = async () => {
    setLiked(!isLiked);
    try {
      const { data } = await likePost(selectedIntranet._id);
      console.log("api called..");
      if (data?.message) {
        console.log("api success message..");
        setAdded(true);
        // toast.success("Liked");
      }
    } catch (e) {
      // setLiked()
    }
  };
  
  const postHistory = () => {

  }

  const handleGoBack = () => {
    setLeaving(true)
  }

  const handleAgree = () => {
    setSelectedIntranet("");
    setLeaving(false);
  }

  return (
    
    <Grid2 container height={"100%"}>
      <Grid2 size={{ xs: selectedIntranet ? 0 : 2 }}>
        {!selectedIntranet && (
          <Sidebar
            categories={categories}
            openCategory={openCategory}
            setOpenCategory={setOpenCategory}
            currentSection={setCategory}
            isIntranet={true}
            handleLogout={handleLogout}
          ></Sidebar>
        )}
      </Grid2>
      {
        isLeaving && <Confirmation handleAgree={handleAgree} open={isLeaving} show={setLeaving} />
      }
      <Grid2 size={{ xs: selectedIntranet ? 12 : 10 }} sx={{ maxHeight: '100vh', overflowY: 'auto' }} >
        {selectedIntranet ? (
          selectedCategory === "LnD" ?
            <LnDTopics selectedIntranet={selectedIntranet} setSelectedIntranet={setSelectedIntranet} />
          :
          <Box width={"100%"} display={"flex"}>
            <Grid2 container gap={"50px"} height={"800px"} width={"100%"}>
              <Grid2 size={{ xs: 6 }}>
                <Box p={2} width={"100%"}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{
                        background: "black",
                        m: 1,
                        height: "fit-content",
                        width: "fit-content",
                        "&:hover": { background: "black" },
                      }}
                      onClick={handleGoBack}
                    >
                      <ArrowBackIosIcon
                        fontSize="small"
                        sx={{ margin: "auto", ml: 0.6, color: "#FFFFFF" }}
                      />
                    </IconButton>
                    <Typography fontSize={17} fontWeight={"bold"}>
                      {selectedIntranet.title}
                    </Typography>
                  </Box>
                  <Box
                    className="scrollbar-container"
                    sx={{ pt: 2, px: 8, maxHeight: "80vh", overflowY: "auto" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={User}
                          style={{ width: "40px", height: "40px" }}
                        />
                        <Box>
                          <Typography sx={{ color: "#242222" }}>
                            {selectedIntranet.author}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "12px" }}>
                            {selectedIntranet.date}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        {isLiked ? (
                          <ThumbUpAltIcon
                            sx={{ cursor: "pointer", color: "#1976d2" }}
                            onClick={handleDisLike}
                          />
                        ) : (
                          <ThumbUpOffAltIcon
                            sx={{ cursor: "pointer", color: "#1976d2" }}
                            onClick={handleLike}
                          />
                        )}
                        <Typography sx={{ fontSize: "10px", color: "grey" }}>
                          {likes} Likes
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{ mt: 1, display: "flex", justifyContent: "center" }}
                    >
                      {selectedIntranet.fileType === "pdf" && (
                        <PDFViewer
                          pdf={`intranet/${selectedIntranet.file}`}
                          width={"100%"}
                          height={"600px"}
                        />
                      )}
                      {selectedIntranet.fileType === "image" && (
                        <img
                          src={`${API_URL}/api/assets/Image/intranet/${selectedIntranet.file}`}
                          style={{
                            // width: '100%',
                            height: "600px",
                            maxWidth: '100%'
                          }}
                        />
                      )}
                      {selectedIntranet.fileType === "video" && (
                        <HLSPlayer videoUrl={`${API_URL}/api/video/stream?key=${selectedIntranet.file}`} postHistory={postHistory} section={"Intranet"} />
                      )}
                    </Box>
                    <Typography sx={{ color: "grey", mt: 2, fontSize: "14px" }}>
                      {selectedIntranet.description}
                    </Typography>
                  </Box>
                </Box>
              </Grid2>
              <Grid2
                size={{ xs: 5 }}
                display={"flex"}
                gap={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  marginTop: "70px",
                }}
              >
                <TextEditor
                  id={selectedIntranet._id}
                  handleCreateComment={handleSubmit}
                />
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography color="black" fontWeight={"bold"}>
                      Comments
                    </Typography>
                    <Chip
                      label={comments.length}
                      color="primary"
                      size="small"
                    />
                  </Box>
                  <CommentList comments={comments} setAdded={setAdded} />
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        ) : (
          <>
            <Box m={5}>
              <Tabs
                value={section}
                onChange={(e, newValue) => setSection(newValue)}
                aria-label="wrapped label tabs example"
              >
                {sections.map((item, index) => (
                  <Tab value={item.name} label={item.name} />
                ))}
              </Tabs>
              {intranetList && intranetList.length > 0 ? (
                <Box display={"flex"} gap={3} mt={3} flexWrap="wrap">
                  {intranetList.map((item, index) => {
                    return (
                      <Box
                        // className="intranet-item"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: 'space-between',
                          padding: "20px",
                          width: "300px",
                          height: "auto",
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          transition: "transform 0.3s ease-in-out",
                          borderRadius: "12px",
                          background: "#ffffff",
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.05)",
                          },
                        }}
                        onClick={() => {
                          setSelectedIntranet(item);
                        }}
                        key={index}
                      >
                        <Box>
                        <Box sx={{ marginBottom: "15px" }}>
                          <img
                            src={
                              item.thumbnail
                                ? `${API_URL}/api/intranet/thumbnail/${item.thumbnail}`
                                : IntranetImg
                            }
                            alt={item.title}
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                        <Box sx={{ textAlign: "start", padding: "0 10px" }}>
                          <Typography
                            fontSize={20}
                            fontWeight="bold"
                            sx={{ marginBottom: "5px" }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{
                              marginBottom: "10px",
                              color: "grey",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              WebkitLineClamp: 5, // Show 5 lines by default
                              maxHeight: "6em",
                            }}
                          >
                            {item.description}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            <Typography>{item.department}</Typography>
                            <Typography>{item.date}</Typography>
                          </Box>
                          <Typography
                            fontSize={14}
                            fontStyle="italic"
                            color="darkgray"
                          >
                            By {item.author}
                          </Typography>
                        </Box>
                          </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            m: 1,
                          }}
                        >
                          <Typography sx={{ color: "grey", fontSize: "12px" }}>
                            {item?.Like?.length} Likes
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "12px" }}>
                            {item?.Comments?.length} Comments
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Typography p={5}>Data will get upload soon...</Typography>
              )}
            </Box>
          </>
        )}
      </Grid2>
    </Grid2>
  );
};
