import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getAnswers } from "../services/AssessmentService";

export const UserAssessmentAnalysis = ({ width, height }) => {
  const navigate = useNavigate();
  let categories;

  const [isSmall, setSmall] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState();

  useEffect(() => {

    if(height) { 
      const calculatedHeight = height * 2.5;
      console.log("calculateHeight:", calculatedHeight)
      setDynamicHeight(calculatedHeight);
    }
  }, [height]);

  useEffect(() => {
    if(dynamicHeight) {
      console.log('dynamicHeight', dynamicHeight);
    }
    setChartState((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        chart: { ...prev.options.chart, height: dynamicHeight },
      },
    }));
  }, [dynamicHeight]);

  useEffect(() => {
    console.log(width);
    if (width < 380) {
      setSmall(true);
    } else {
      setSmall(false);
    }
  }, [width]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAnswers();

        const scores =
          response.data.myScore.filter(
            (item) => item.assessment.module !== null
          ) || [];
        categories = scores.map((score) => score.assessment.name);
        const percentages = scores.map((score) => score.percentage);

        setChartState((prevState) => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: percentages }],
          options: {
            ...prevState.options,
            xaxis: { ...prevState.options.xaxis, categories },
          },
        }));
      } catch (e) {
        if (e.message === "Token Expired") {
          console.log("logout>>>>>>>");
          localStorage.removeItem("token");
          navigate("/");
        }
      }
    })();
  }, [navigate]);

  const colors = categories?.map(
    (_, index) => `hsl(${(index * 360) / categories.length}, 70%, 50%)` // Distinct colors using HSL
  );

  const [chartState, setChartState] = useState({
    series: [
      {
        name: "",
        data: [], // Y-axis values
      },
    ],

    options: {
      chart: {
        height: dynamicHeight,
        width: width,
        type: "bar",
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: "20%",
          barHeight: "50%",
          distributed: true,
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [], // X-axis labels
        title: {
          text: "",
        },
        labels: {
          show: true,
          style: {
            colors: colors,
            fontSize: "8px",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val;
          },
        },
        min:0,
        max:100,
        title: {
          // text: "Percentage (%)",
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val.toFixed(1)}%`,
        },
      },
    },
  });

  return (
    <div
      className="graph-container"
      style={{
        textAlign: "start",
        background: "white",
        padding: "0px",
        paddingLeft: '20px',
        paddingRight: '20px',
        margin: "0px",
        width: "100%",
        height: '100%',
        borderRadius: "20px",
        overflowY: "auto",
        // margin: "0px 0px 0px 50px",
      }}
    >
      <h4>Attended Assessment Analysis</h4>
      <div id="chart">
        <ReactApexChart
          options={{
            ...chartState.options,
            chart: { ...chartState.options.chart, width, height }, // Update width and height
          }}
          series={chartState.series}
          type="bar"
          height={dynamicHeight}
        />
      </div>
    </div>
  );
};
