import axios from "axios";
import { API_URL, getHeaders } from "../config";

// Function to handle logout and redirect
const handleLogout = () => {
  localStorage.removeItem("token");
  window.location.href = "/"; // Directly use string, no need for parentheses around it
};

// Function to get assessment by ID
export const getAssessment = async (id, moduleType) => {
  try {
    const { data } = await axios.get(`${API_URL}/api/assessment?id=${id}&moduleType=${moduleType}`, {
      headers: getHeaders().headers,
    });
    return data || {}; // Ensure data is always returned, even if undefined
  } catch (error) {
    const status = error?.response?.status;
    if (!status) return { error }; // Handle cases where status may not exist

    if (status === 401 || status === 403) {
      handleLogout(); // Log out if unauthorized
    }
    return { error }; // Return error for other cases
  }
};

export const getIntranetAssessment = async (id, moduleType) => {
  try {
    const { data } = await axios.get(`${API_URL}/api/assessment/intranet?id=${id}&moduleType=${moduleType}`, {
      headers: getHeaders().headers,
    });
    return data || {}; // Ensure data is always returned, even if undefined
  } catch (error) {
    const status = error?.response?.status;
    if (!status) return { error }; // Handle cases where status may not exist

    if (status === 401 || status === 403) {
      handleLogout(); // Log out if unauthorized
    }
    return { error }; // Return error for other cases
  }
};

export const getWarmup = async (id) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/api/assessment/warmup?id=${id}`,
      {
        headers: getHeaders().headers,
      }
    );
    return data || {}; // Ensure data is always returned, even if undefined
  } catch (error) {
    const status = error?.response?.status;
    if (!status) return { error }; // Handle cases where status may not exist

    if (status === 401 || status === 403) {
      handleLogout(); // Log out if unauthorized
    }
    return { error }; // Return error for other cases
  }
};

// Function to submit answers for assessment
export const submitAnswers = async (submissionData) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/assessment/submitAssessment`,
      submissionData,
      { headers: getHeaders().headers }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const status = error?.response?.status;
    if (!status) return { error }; // Handle cases where status may not exist

    if (status === 401 || status === 403) {
      handleLogout(); // Log out if unauthorized
    }
    return { error }; // Return error for other cases
  }
};
export const getAnswers = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/assessment/getAnswer`, {
      headers: getHeaders().headers,
    });
    return { success: true, data: response.data };
  } catch (error) {
    const status = error?.response?.status;
    if (!status) return { error }; // Handle cases where status may not exist

    if (status === 401 || status === 403) {
      handleLogout(); // Log out if unauthorized
    }
    return { error }; // Return error for other cases
  }
};

export const getAllWarmUp = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/api/assessment/warmup/all`, {
      headers: getHeaders().headers,
    });
    if (data) {
      return data;
    }
    return data; // Add a return for other response cases if needed
  } catch (error) {
    const axiosError = error; // Cast error as AxiosError
    const status = axiosError.response?.status;

    if (!status) return { error: axiosError }; // Handle cases where status may not exist

    if (status === 401 || status === 403) {
      handleLogout(); // Log out if unauthorized
    }

    return { error: axiosError }; // Return error for other cases
  }
};
