import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { UserModuleAnalysis } from "./UserModuleAnalysis";
import { UserModuleVideoAnalysis } from "./UserModuleVideoAnalysis";
import { UserAssessmentAnalysis } from "./UserAssessmentAnalysis";
import { UserwarmupAnalysis } from "./UserWarmupAnalysis";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const GraphGridLayout = ({
  layouts: initialLayouts,
  containerPadding = [10, 10],
  margin = [10, 10],
  cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  rowHeight = 50,
  ...props
}) => {


  const [layouts, setLayouts] = useState(() => {
    const savedLayouts = localStorage.getItem("savedLayouts");
    return savedLayouts ? JSON.parse(savedLayouts) :
    {
      lg: [
        { x: 0, y: 0, w: 4, h: 3, i: "0" },
        { x: 4, y: 0, w: 4, h: 3, i: "1" },
        { x: 8, y: 0, w: 4, h: 3, i: "2" },
        { x: 0, y: 3, w: 4, h: 3, i: "3" },
      ],
      md: [
        { x: 0, y: 0, w: 3, h: 3, i: "0" },
        { x: 3, y: 0, w: 3, h: 3, i: "1" },
        { x: 6, y: 0, w: 3, h: 3, i: "2" },
        { x: 0, y: 3, w: 3, h: 3, i: "3" },
      ],
      sm: [
        { x: 0, y: 0, w: 6, h: 3, i: "0" },
        { x: 0, y: 3, w: 6, h: 3, i: "1" },
        { x: 0, y: 6, w: 6, h: 3, i: "2" },
        { x: 0, y: 9, w: 6, h: 3, i: "3" },
      ],
      xs: [
        { x: 0, y: 0, w: 4, h: 3, i: "0" },
        { x: 0, y: 3, w: 4, h: 3, i: "1" },
        { x: 0, y: 6, w: 4, h: 3, i: "2" },
        { x: 0, y: 9, w: 4, h: 3, i: "3" },
      ],
      xxs: [
        { x: 0, y: 0, w: 2, h: 3, i: "0" },
        { x: 0, y: 3, w: 2, h: 3, i: "1" },
        { x: 0, y: 6, w: 2, h: 3, i: "2" },
        { x: 0, y: 9, w: 2, h: 3, i: "3" },
      ],
    };
  });

  useEffect(() => {
    // Save layouts to localStorage whenever they change
    localStorage.setItem("savedLayouts", JSON.stringify(layouts));
  }, [layouts]);

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [mounted, setMounted] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // State to control dragging dynamically

  useEffect(() => {
    setMounted(true);
  }, []);

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  const onLayoutChange = (layout, newLayouts) => {
    setLayouts(newLayouts);
    if (props.onLayoutChange) {
      props.onLayoutChange(layout, newLayouts);
    }
  };

  const calculateDimensions = (layoutItem) => {
    console.log('calculte...')
    const { w, h } = layoutItem;
    console.log(layoutItem);
    localStorage.setItem('dimensions', JSON.stringify(layoutItem));
  
    // Default to current breakpoint values or fallback
    const currentCols = cols[currentBreakpoint] || cols.lg;
    const colWidth = (1200 - margin[0] * (currentCols - 1)) / currentCols;
    const width = w * colWidth - containerPadding[0];
    const height = h * rowHeight - margin[1]; // Adjusted height calculation
  
    return { width, height: Math.max(height, 50) }; // Ensure a minimum height
  };

  const generateDOM = () => {
    const components = [
      <UserModuleAnalysis disableDragging={setIsDragging} />,
      <UserModuleVideoAnalysis disableDragging={setIsDragging} />,
      <UserAssessmentAnalysis disableDragging={setIsDragging} />,
      <UserwarmupAnalysis disableDragging={setIsDragging} />,
    ];

    return _.map(layouts[currentBreakpoint], (layout, i) => {
      const dimensions = calculateDimensions(layout);

      return (
        <div
          key={layout.i}
          data-grid={layout}
          style={{
            background: "#ffffff",
            borderRadius: "10px",
            height: '100%',
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            pointerEvents: "auto",
            
          }}
        >
          {React.cloneElement(components[i], { ...dimensions })}
        </div>
      );
    });
  };

  return (
    <div className="mb-4" style={{ height: 'fit-content' }}>
      <ResponsiveReactGridLayout
        {...props}
        layouts={layouts}
        containerPadding={containerPadding}
        margin={margin}
        measureBeforeMount={false}
        useCSSTransforms={mounted}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        isDraggable={!isDragging}
        isResizable={!isDragging}
        cols={cols}
      >
        {generateDOM()}
      </ResponsiveReactGridLayout>
    </div>
  );
};

GraphGridLayout.defaultProps = {
  layouts: null,
  containerPadding: [10, 10],
  margin: [20, 20],
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  rowHeight: 50,
  onLayoutChange: null,
};
