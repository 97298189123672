import { Box, TextareaAutosize, Typography } from "@mui/material";
import User from "../assets/images/user.png";
import "../assets/styles/scrollbar.css";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useEffect, useState } from "react";
import {
  createCommentLike,
  createCommentReply,
  getCommentReplies,
} from "../services/IntranetService";
import { toast } from "react-toastify";

export const CommentList = ({ comments, setAdded }) => {
  const [text, setText] = useState("");
  const [commentId, setCommentId] = useState("");
  const [commentReplies, setCommentReplies] = useState([]);
  const [commentListId, setCommentListId] = useState("");
  const [isLiked, setLiked] = useState(false);
  const [likedComments, setLikedComments] = useState([]);

  useEffect(() => {
    if (commentListId) {
      (async () => {
        try {
          const { data } = await getCommentReplies(commentListId);
          setCommentReplies(data?.data);
        } catch (e) {}
      })();
    }
  }, [commentListId]);

  useEffect(() => {
    if(comments?.length) {
        const likedComments = [];
        comments.forEach((item) => {
            if(item.isLiked) {
                likedComments.push(item._id);
            }
        });
        setLikedComments(likedComments);
    }
  }, [comments]);

  const handleSubmit = async (text, id) => {
    try {
      const { data } = await createCommentReply(text, id);
      if (data.message) {
        setAdded(true);
        setCommentId("");
        toast.success(data?.message);
      }
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  const handleLike = async (id) => {
    setLikedComments((prev) => ([...prev, id]));
    
    try {
      const { data } = await createCommentLike(id);
      console.log("api called..");
      if (data?.message) {
        console.log("api success message..");
        // setAdded(true);
        // toast.success("Liked");
      }
    } catch (e) {
      // setLiked()
    }
  };

  const handleDisLike = async (id) => {
    console.log('dislike');
    console.log("all",likedComments);
    const comments = likedComments.filter((item) => item !== id);
    console.log("liked",comments);
    setLikedComments(comments);
    
    try {
      const { data } = await createCommentLike(id);
      console.log("api called..");
      if (data?.message) {
        console.log("api success message..");
        // setAdded(true);
        // toast.success("Liked");
      }
    } catch (e) {
      // setLiked()
    }
  };

  return (
    <Box
      className="scrollbar-container"
      sx={{ maxHeight: "60vh", overflowY: "auto" }}
    >
      {comments.map((comment, index) => (
        <Box key={index} p={1} m={1}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={User} style={{ width: "40px", height: "40px" }} />
            <Typography fontSize={14}>{comment.userName}</Typography>
          </Box>
          <Typography ml={5} fontSize={13} color="grey">
            {comment.text}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              ml: "40px",
              mt: "10px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              {likedComments.includes(comment._id) ? (
                <ThumbUpAltIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "#1976d2" }}
                  onClick={() => handleDisLike(comment._id)}
                />
              ) : (
                <ThumbUpOffAltIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "#1976d2" }}
                  onClick={() => handleLike(comment._id)}
                />
              )}
              <ChatBubbleOutlineIcon
                fontSize="small"
                style={{ color: "#1976d2", cursor: "pointer" }}
                onClick={(e) => setCommentId(comment._id)}
              />
            </Box>
            {comment.replyCount > 0 ? (
              <Typography
                style={{
                  fontSize: "10px",
                  marginLeft: "",
                  color: "grey",
                  cursor: "pointer",
                }}
                onClick={(e) => setCommentListId(comment._id)}
              >
                View {comment.replyCount} more reply
              </Typography>
            ) : (
              <></>
            )}
          </Box>
          {comment._id == commentId && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <TextareaAutosize
                placeholder="Add Comment"
                onChange={(e) => setText(e.target.value)}
                style={{
                  paddingTop: "10px",
                  //   maxHeight: "50px",
                  maxWidth: "93%",
                  minWidth: "93%",
                  //   minHeight: "50px",
                  width: "93%",
                  height: "30px",
                  //   backgroundColor: "#f8f9fa",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  marginLeft: "40px",
                  marginTop: "10px",
                }}
                onFocus={(e) => {
                  e.target.style.outline = "none";
                }}
              />
              <button
                onClick={() => handleSubmit(text, comment._id)}
                style={{
                  backgroundColor: "#ff6b35",
                  color: "#fff",
                  border: "none",
                  borderRadius: "8px",
                  padding: "5px 10px",
                  cursor: "pointer",
                  marginTop: "10px",
                  width: "fit-content",
                }}
              >
                Submit
              </button>
            </Box>
          )}
          {commentListId === comment._id && (
            <Box sx={{ ml: 2 }}>
              {comment.commentReplies.map((commentReply) => {
                return (
                  <Box key={index} p={1} m={1}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={User}
                        style={{ width: "40px", height: "40px" }}
                      />
                      <Typography fontSize={14}>
                        {commentReply.userName}
                      </Typography>
                    </Box>
                    <Typography ml={5} fontSize={13} color="grey">
                      {commentReply.text}
                    </Typography>
                  </Box>
                );
              })}
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "grey",
                  m: 1,
                  cursor: "pointer",
                }}
                onClick={(e) => setCommentListId("")}
              >
                Hide Replies
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
