import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getUserModuleReport } from "../services/UserReportService";
import { Grid2, Tab, Tabs } from "@mui/material";

export const UserModuleAnalysis = ({ width, height, disableDragging }) => {
  const colors = [
    "#2C3E50", // Dark Blue
    "#34495E", // Dark Slate Gray
    "#16A085", // Dark Teal
    "#1ABC9C", // Dark Turquoise
    "#8E44AD", // Dark Purple
    "#9B59B6", // Purple
    "#2980B9", // Dark Blue
    "#2ECC71", // Green
    "#27AE60", // Dark Green
    "#E74C3C", // Dark Red
    "#C0392B", // Red
    "#F39C12", // Dark Yellow
    "#F1C40F", // Yellow
    "#D35400", // Dark Orange
    "#E67E22", // Orange
    "#F7DC6F", // Light Yellow
  ];

  const [isSmall, setSmall] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState();

  useEffect(() => {

    if(height) { 
      const calculatedHeight = height * 2.5;
      console.log("calculateHeight:", calculatedHeight)
      setDynamicHeight(calculatedHeight);
    }
  }, [height]);

  useEffect(() => {
    if(dynamicHeight) {
      console.log('dynamicHeight', dynamicHeight);
    }
    setChartState((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        chart: { ...prev.options.chart, height: dynamicHeight },
      },
    }));
  }, [dynamicHeight]);


  useEffect(() => {
    console.log(width);
    if (width < 380) {
      setSmall(true);
    } else {
      setSmall(false);
    }
  }, [width]);

  const navigate = useNavigate();

  const ranges = [
    { name: "This Month", displayName: "1 M" },
    { name: "This Week", displayName: "1 W" },
    { name: "Today", displayName: "1 D" },
    { name: "Custom", displayName: "Custom" },
  ];

  const [selectedRange, setRange] = useState("This Month");
  const [startDate, setStartDate] = useState("Start");
  const [endDate, setEndDate] = useState("End");

  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState("All");

  const [pptOverall, setPPTOverall] = useState([]);

  const [chartState, setChartState] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        height: dynamicHeight,
        width: width,
        type: "bar", // Use exact string literal here
        events: {},
      },
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: "20%",
          barHeight: "50%",
          distributed: true,
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        title: {
          text: "Module",
          offsetY: 20, // Add margin from the top
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            color: "#333",
          },
        },
        categories: [],
        labels: {
          show: true,
          style: {
            colors: colors,
            fontSize: "5px",
          },
        },
      },
      yaxis: {
        // title: {
        //   text: "Module",
        // },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const value = series[seriesIndex][dataPointIndex];
          return `<div style="padding: 5px; color: #fff; background: #333; border-radius: 5px;">
                      ${value} Min
                    </div>`;
        },
      },
    },
  });

  useEffect(() => {
    if (selectedRange === "Custom") {
      const today = new Date();
      const yesterday = new Date(today);

      yesterday.setDate(today.getDate() - 1);

      setStartDate(yesterday.toISOString().split("T")[0]);
      setEndDate(today.toISOString().split("T")[0]);
    }
  }, [selectedRange]);

  useEffect(() => {
    (async () => {
      try {
        const { categories, series, pptOverall } = await getUserModuleReport(
          selectedRange,
          startDate,
          endDate
        );
        console.log("pptOverall", pptOverall);
        setPPTOverall(pptOverall);
        setChartState((prev) => ({
          ...prev,
          series: [{ data: series }],
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: categories,
            },
          },
        }));
      } catch (e) {
        if (e.message === "Token Expired") {
          console.log("logout>>>>>>>");
          localStorage.removeItem("token");
          navigate("/");
        }
      }
    })();
  }, [selectedModule, selectedRange, startDate, endDate]);

  return (
    <div
      className="graph-container scrollbar-container"
      style={{
        textAlign: "start",
        background: "white",
        padding: "0px",
        paddingLeft: '20px',
        paddingRight: '20px',
        margin: "0px",
        width: "100%",
        height: '100%',
        borderRadius: "20px",
        overflowY: "auto",
        // margin: '0px 0px 0px 50px',
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4>Module Wise PPT Analysis</h4>
        <Tabs
          value={selectedRange}
          onChange={(e, newValue) => {
            e.stopPropagation();
            setRange(newValue);
          }}
          onMouseDown={() => disableDragging(true)}
          onMouseUp={() => disableDragging(false)}
        >
          {ranges.map((item, index) => {
            return (
              <Tab
                sx={{
                  padding: "0px 12px",
                  fontSize: "12px",
                  width: "20px",
                  minWidth: "60px",
                }}
                key={index}
                value={item.name}
                label={item.displayName}
              />
            );
          })}
        </Tabs>
      </div>

      {/* <select
        id="classDropdown"
        style={{ marginLeft: "10px" }}
        onChange={(e) => setRange(e.target.value)}
      >
        {ranges.map((item, index) => {
          return <option value={item}>{item}</option>;
        })}
      </select> */}
      {selectedRange === "Custom" && (
        <>
          <input
            type="date"
            style={{ marginLeft: "10px" }}
            className="date-input"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            onMouseDown={() => disableDragging(true)}
            onMouseUp={() => disableDragging(false)}
          />
          <input
            type="date"
            style={{ marginLeft: "10px" }}
            className="date-input"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            onMouseDown={() => disableDragging(true)}
            onMouseUp={() => disableDragging(false)}
          />
        </>
      )}

      <Grid2 container gap={isSmall ? 5 : 0 }>
        <Grid2  size={{ md: isSmall ? 12 : 3 }}>
        <div style={{ marginTop: "20px", display: 'flex', flexDirection: isSmall ? 'row' : 'column', alignItems: isSmall && 'start', justifyContent: isSmall && 'space-between' }}>

            <h5 style={{ padding: 0, margin: 0, fontSize: "18px" }}>
              Current Month
            </h5>
            <div>
              <h5 style={{ padding: 0, margin: 0, marginTop: isSmall ? '0px' : "10px" }}>
                Total Time
              </h5>
              <h5
                style={{
                  fontSize: "22px",
                  fontWeight: "normal",
                  color: "#1976d2",
                  padding: 0,
                  margin: 0,
                }}>
                {pptOverall?.totalTimeOfAllModules || "N/A"}
              </h5>
            </div>
            <div>
              <h5 style={{ padding: 0, margin: 0, marginTop: isSmall ? '0px' : "20px" }}>
                Top Module
              </h5>
              <h5
                style={{
                  fontSize: "22px",
                  fontWeight: "normal",
                  color: "#1976d2",
                  padding: 0,
                  margin: 0,
                }}
              >
                {pptOverall?.mostUsedModule?.module || "N/A"}
              </h5>
            </div>
          </div>
        </Grid2>

        <Grid2 size={{ md: isSmall ? 12 : 9 }}>
          <div id="chart">
            <ReactApexChart
              options={chartState.options}
              series={chartState.series}
              type="bar"
              height={dynamicHeight}
              // width={width || "100%"}
            />
          </div>
        </Grid2>
      </Grid2>
    </div>
  );
};
