import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getAnswers } from "../services/AssessmentService";

export const UserwarmupAnalysis = ({ width, height }) => {
  const navigate = useNavigate();

  let categories;

  const [isSmall, setSmall] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState();

  useEffect(() => {
    console.log(width);
    if (width < 380) {
      setSmall(true);
    } else {
      setSmall(false);
    }
  }, [width]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAnswers();

        if (response?.data) {
          const scores =
            response.data.myScore.filter(
              (item) => item.assessment.module === null
            ) || [];
          const categories = scores.map((score) => score.assessment.name);
          const percentages = scores.map((score) => score.percentage);

          setChartState((prevState) => ({
            ...prevState,
            series: [{ ...prevState.series[0], data: percentages }],
            options: {
              ...prevState.options,
              xaxis: { ...prevState.options.xaxis, categories },
            },
          }));
        }
      } catch (e) {
        if (e.message === "Token Expired") {
          console.log("logout>>>>>>>");
          localStorage.removeItem("token");
          navigate("/");
        }
      }
    })();
  }, [navigate]);

  const colors = categories?.map(
    (_, index) => `hsl(${(index * 360) / categories.length}, 70%, 50%)` // Distinct colors using HSL
  );

  const [chartState, setChartState] = useState({
    series: [
      {
        name: "Percentage",
        data: [], // Y-axis values
      },
    ],
    options: {
      chart: {
        height: height || 350,
        width: width,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: colors, // Use the colors array for bar colors
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: "20%",
          barHeight: "50%",
          distributed: true,
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [], // X-axis labels
        title: {
          text: "",
        },
        labels: {
          show: true,
          //   formatter: (val) => val.toFixed(1),
          style: {
            // colors: colors,
            fontSize: "8px",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val;
          },
        },
        min: 0,
        max: 100,
        title: {
          // text: "Percentage (%)",
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val.toFixed(1)}%`,
        },
      },
    },
  });

  return (
    <div
      className="graph-container"
      style={{
        textAlign: "start",
        background: "white",
        padding: "0px",
        paddingLeft: '20px',
        paddingRight: '20px',
        margin: "0px",
        width: "100%",
        height: '100%',
        borderRadius: "20px",
        overflowY: "auto",
      }}
    >
      <h4>Attended Warmup Analysis</h4>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          height={350}
          // width={width || "100%"}
        />
      </div>
    </div>
  );
};
