import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include Quill's snow theme
import "../assets/styles/react-quill.css";
import { Box, TextareaAutosize } from '@mui/material';
import { submitComment } from '../services/IntranetService';
import { toast } from 'react-toastify';

const TextEditor = ({ id, handleCreateComment }) => {

    const [text, setText] = useState('');
    // const notify = toast((message) => )

    const handleSubmit = () => {
        handleCreateComment(text, id)
    }

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'], // Formatting options
            [{ list: 'ordered' }, { list: 'bullet' }], // Lists
            ['link', 'image'], // Add links and images
            ['clean'], // Clear formatting
        ],
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%'  }}>
            {/* <ReactQuill
                value={text}
                onChange={setText}
                modules={modules}
                placeholder="Add comment..."
                style={{
                    borderRadius: '8px',
                    backgroundColor: '#f8f9fa',
                    width: "100%",
                    height: "100px",
                }}
            /> */}
            <TextareaAutosize
                placeholder='Add Comment'
                onChange={(e) => setText(e.target.value)}
                style={{
                    paddingTop: '10px',
                    maxHeight: '100px',
                    maxWidth: '100%',
                    minWidth: '100%',
                    minHeight: '100px',
                    width: '100%',
                    height: '100px',
                    backgroundColor: '#f8f9fa',
                    border: 'none',
                    borderRadius: '10px',
                    paddingLeft: '10px'
                }}
                onFocus={(e) => {
                    e.target.style.outline = "none";
                }}
            /> 
            <button
                onClick={handleSubmit}
                style={{
                    backgroundColor: '#ff6b35',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    padding: '5px 10px',
                    cursor: 'pointer',
                    marginTop: '10px',
                    width: 'fit-content'
                }}
            >
                Submit
            </button>
        </Box>
    );
};

export default TextEditor;
