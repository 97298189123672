import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { FullScreenPPTViewer } from "./components/FullScreenPPTViewer";
import { Test } from "./pages/Test";
import { Login } from "./pages/Login";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScreenTimeProvider, useScreenTime } from "./context/ScreenTimeContext";
import { Categories } from "./pages/Categories";
import IndividualAssessment from "./pages/IndividualAssessment";
import { Intranet } from "./pages/Intranet";
import { IntranetDetails } from "./pages/IntranetDetails";

function App() {
  useEffect(() => {
    // Disable right-click
    const handleRightClick = (event) => {
      event.preventDefault();
    };

    // Disable Ctrl + Shift + I and F12
    const handleKeyDown = (event) => {
      // Check if Ctrl+Shift+I (keycode for 'I' is 73)
      if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
        event.preventDefault();
      }
      // Check if F12 is pressed (keycode for F12 is 123)
      if (event.keyCode === 123) {
        event.preventDefault();
      }
    };

    // Add event listeners for right-click and keydown
    window.addEventListener("contextmenu", handleRightClick);
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listeners when the component is unmounted
    return () => {
      window.removeEventListener("contextmenu", handleRightClick);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <ScreenTimeProvider>
      <AppTracker />
      <Router>
        <Routes>
          <Route path="/dashboard" element={<Categories />} />
          <Route path="/modules" element={<Home />} />
          <Route path="/home" element={<Test />} />
          <Route path="/" element={<Login />} />
            <Route
              path="/assessment/:id/:type/:token"
              element={<IndividualAssessment />}
            />
          <Route path="/intranet" element={<Intranet />} />
          {/* <Route path="/intranet/:id" element={<IntranetDetails />} /> */}
        </Routes>
      </Router>
        
      </ScreenTimeProvider>
    </div>
  );
}

export default App;

const AppTracker = () => {
  const { startTracking, stopTracking } = useScreenTime();

  useEffect(() => {
    startTracking();

    const handleBeforeUnload = () => {
      stopTracking();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        stopTracking();
      } else if (document.visibilityState === "visible") {
        startTracking();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      stopTracking();
    };
  }, [startTracking, stopTracking]);

  return null;
};
