import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { submitModuleFeedback } from "../services/moduleFeedback";

export const buttonStyle = {
  background: "#22b378",
  color: "white",
  borderRadius: 2,
  textTransform: "none",
  fontWeight: 400,
  "&:hover": {
    background: "grey",
  },
};

export const title = {
  fontSize: { xl: "1rem", xs: "1.25rem" },
  fontWeight: 600,
  paddingTop: '10px',
  paddingBottom: '0px'
};

export const contentStyle = {
  fontSize: { xl: "0.8rem", xs: "0.8rem" },
  fontWeight: 400,
  textTransform: "none",
};

export default function ModuleFeedback({
  content,
  handleAgree,
  open,
  show,
  module
}) {
  const [feedback, setFeedback] = React.useState("");
  const [rating, setRating] = React.useState(0);

  React.useEffect(() => {
    if(module) {
      console.log('module____________', module);
    }
  }, [module]);

  const handleClose = () => {
    show(!open);
    handleAgree();
  };

  const handleSubmit = async() => {
    // Handle feedback submission here
    console.log("Feedback:", feedback);
    console.log("Rating:", rating);
    handleAgree();
    const { data } = await submitModuleFeedback(feedback, rating, module?._id);
    console.log('message', data?.message);
  };

  return (
    <React.Fragment >
      <Dialog
        sx={{ borderRadius: 5 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={title} id="alert-dialog-title">
          {module?.title}
        </DialogTitle>
        <DialogContent sx={{ width: '300px' }}>
          <DialogContentText sx={contentStyle} id="alert-dialog-description">
            {content}
          </DialogContentText>
          {/* Feedback Input */}
          <TextField
            fullWidth
            multiline
            rows={2}
            placeholder="Write your feedback here..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            sx={{ marginTop: 2, borderRadius: '20px' }}
          />
          {/* Star Rating */}
          <Stack direction="row" spacing={1} alignItems="center" justifyContent={"center"} sx={{ marginTop: 2 }}>
            {/* <DialogContentText sx={{ fontWeight: 400 }}>Rate:</DialogContentText> */}
            <Rating
              name="feedback-rating"
              sx={{ fontSize: '30px' }}
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid #e4e4e4", display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleClose} sx={buttonStyle} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{
              ...buttonStyle,
              backgroundColor: "lightgrey",
              color: "black",
            }}
          >
            Submit Feedback
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
