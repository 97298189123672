import { useEffect, useState } from "react";
import DC from "../assets/images/DC.png";
import ERP from "../assets/images/ERP.jpg";
import FAQ from "../assets/images/FAQ.png";
import Sales from "../assets/images/Sales.jpg";
import Support from "../assets/images/support.png";
import Intranet from "../assets/images/Intranet.png";
import { UserModuleAnalysis } from "../components/UserModuleAnalysis";
import { getUserActivity, getUserPermissionSections } from "../services/UserReportService";
import { UserModuleVideoAnalysis } from "../components/UserModuleVideoAnalysis";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { LeaderBoardSection } from "../components/LeaderBoardSection";
import "../assets/styles/progress.css";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {  Grid2, Stack } from "@mui/material";
import { UserAssessmentAnalysis } from '../components/UserAssessmentAnalysis'
import { UserwarmupAnalysis } from '../components/UserWarmupAnalysis'
import { GraphGridLayout } from "../components/GraphGridLayout";
import ResizableDraggableGrid from "../components/ResizableDraggableGrid";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: "linear-gradient(135deg, rgb(78, 115, 223), rgb(31, 42, 122))",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

export const Categories = () => {
  const userName = localStorage.getItem("userName");

  const navigate = useNavigate();

  const sections = [
    {
      name: "ERP",
      image: ERP,
      permission: ["Sales"],
      totalModuleUsed: 70,
    },
    {
      name: "LMS DC",
      image: DC,
      permission: ["Sales"],
      totalModuleUsed: 60,
    },
    {
      name: "FAQ",
      image: FAQ,
      permission: ["Sales"],
      totalModuleUsed: 10,
    },
    {
      name: "Sales",
      image: Sales,
      permission: ["Sales"],
      totalModuleUsed: 40,
    },
    {
      name: "Support",
      image: Support,
      permission: ["Support"],
      totalModuleUsed: 20,
    },
    {
      name: "Intranet",
      image: Intranet,
      permission: ["All"]
    }
  ];

  const [loginCount, setLoginCount] = useState(0);
  const [activeTime, setActiveTime] = useState(0);
  const [userSections, setUserSections] = useState([]);

  const role = localStorage.getItem("role");

  useEffect(() => {
    (async() => {
      const data  = await getUserPermissionSections();
      console.log("user permission sections...",data);
      setUserSections(data);
    })()
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { count, time } = await getUserActivity();
        setLoginCount(count);
        setActiveTime(time);
      } catch (e) {
        if (e) {
          console.log("Error:", e);
          if (e.message === "Unauthorized") {
            handleLogout();
          }
        }
      }
    })();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("savedLayouts");
    navigate("/");
  };

  const handleSelectSection = (section) => {
    localStorage.setItem("section", section);
    if (section === "Intranet") {
      navigate("/intranet");
    } else {
      navigate("/modules");
    }
  };

  const getImage = (section) => {
    const image = sections.find((item) => section === item.name);
    console.log('section img', image);
    return image?.image;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        <h2 style={{ margin: "30px 0px 20px 50px" }}>Hello {userName}</h2>
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "20px",
            gap: "20px",

            flexShrink: 1,
          }}
        >
          <div
            className="user-activity"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              // flexDirection: "column",
              width: "fit-content",
              height: "50px",
              borderRadius: "20px",
              background: "linear-gradient(135deg, #4e73df, #1f2a7a)",
              padding: "10px 15px",
              flexShrink: 1, // Allow the item to shrink
            }}
          >
            <p
              style={{
                color: "rgb(255 255 255)",
                fontSize: "14px",
                margin: 0,
                fontWeight: "normal",
                textWrap: "nowrap",
              }}
            >
              Total Active Screen Time:{" "}
            </p>
            <p
              style={{
                color: "white",
                fontSize: "18px",
                margin: 0,
                textWrap: "nowrap",
              }}
            >
              {activeTime}
            </p>
          </div>
          <div
            className="user-activity"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
              // flexDirection: "column",
              width: "100%",
              height: "50px",
              borderRadius: "20px",
              background: "linear-gradient(180deg, #87CEEB, #4682B4)",
              padding: "10px 15px",
              flexShrink: 1, // Allow the item to shrink
            }}
          >
            <p
              style={{
                color: "rgb(255 255 255)",
                fontSize: "14px",
                margin: 0,
                fontWeight: "normal",
              }}
            >
              Total Login Count :{" "}
            </p>
            <p style={{ color: "white", fontSize: "18px", margin: 0 }}>
              {loginCount}
            </p>
          </div>
        </div>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100px",
            marginTop: "auto",
            alignSelf: "center",
            marginBottom: "10px",
            backgroundColor: "white",
            border: "1px solid lightblue",
            borderRadius: "10px",
            color: "rgb(78, 115, 223)",
            padding: "10px",
            fontSize: "15px",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          <LogoutIcon style={{ color: "rgb(78, 115, 223)" }} />
          Logout
        </button>
      </div>
      <Grid2
        container
        sx={{
          // display: "flex",
          // justifyContent: "space-between",
          // alignItems: "start",
          // flexWrap: "nowrap",
          // width: "96vw",
          px: '50px',
          marginTop: "50px",
          alignItems: 'start',
          justifyContent: 'space-between'
        }}
      >
        <Grid2
          size={{ lg: 9 }}
          style={{
            marginTop: "10px",
            // marginLeft: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "20px",
            // width: "60vw",
            flexWrap: 'wrap',
            // Allow items to shrink to fit on smaller screens
          }}
        >
          {userSections && userSections.length > 0 && userSections.map((section) => (
            <div
              className="report-item"
              style={{
                // display:
                  // section.permission.includes(role) ||
                  // section.permission.includes("All")
                  //   ? "flex"
                  //   : "none",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: 'space-between',
                width: "200px", // Default width for each item
                height: "300px",
                textAlign: "start",
                borderRadius: "20px",
                marginBottom: "20px", // Ensure there’s space between items
                flexShrink: 1, // Allow item to shrink when needed
              }}
              onClick={() => handleSelectSection(section.name)}
            >
              <img
                src={getImage(section.name)}
                alt=""
                style={{ width: "100%", height: "150px" }}
              />
              <h3 style={{ fontWeight: "normal" }}>{section.name}</h3>
              <div>
                <p style={{ color: "grey", marginLeft: "2px", margin: 0 }}>
                  Total module usage
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={section.totalPercentage ? section.totalPercentage?.toFixed(1) : 0}
                    />
                  </Stack>
                  <p
                    style={{ fontSize: "13px", color: "grey" }}
                  >{section.totalPercentage ? `${section.totalPercentage?.toFixed()}%` : `0%`}</p>
                </div>
              </div>
            </div>
          ))}
          <div
              className="report-item"
              style={{
                // display:
                  // section.permission.includes(role) ||
                  // section.permission.includes("All")
                  //   ? "flex"
                  //   : "none",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: 'space-between',
                width: "200px", // Default width for each item
                height: "300px",
                textAlign: "start",
                borderRadius: "20px",
                marginBottom: "20px", // Ensure there’s space between items
                flexShrink: 1, // Allow item to shrink when needed
              }}
              onClick={() => handleSelectSection('Intranet')}
            >
              <img
                src={getImage('Intranet')}
                alt=""
                style={{ width: "100%", height: "150px" }}
              />
              <h3 style={{ fontWeight: "normal" }}>Intranet</h3>
              <div>
                <p style={{ color: "grey", marginLeft: "2px", margin: 0 }}>
                  Total module usage
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={0}
                    />
                  </Stack>
                  <p
                    style={{ fontSize: "13px", color: "grey" }}
                  >
                    {`0%`}
                  </p>
                </div>
              </div>
          </div>
        </Grid2>

        {/** Intranet section */}
        <Grid2
          size={{ sm: 3 }}
          style={{
            maxWidth: "330px",
            padding: "20px",
            borderRadius: "15px",
            background: "linear-gradient(135deg, #4C4177, #2A5470)",
            color: "white",
            textAlign: "left",
            // margin: "20px auto",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            fontFamily: "'Arial', sans-serif",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <LeaderBoardSection />

          {/* <div
            style={{ marginTop: "20px", display: "flex", alignItems: "center" }}
          >
            <img
              src="https://via.placeholder.com/50"
              alt="Lecturer"
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "2px solid rgba(255, 255, 255, 0.8)",
                marginRight: "15px",
              }}
            />
            <div>
              <h1 style={{ margin: 0, fontSize: "2.5rem", fontWeight: "bold" }}>
                91.2%
              </h1>
              <p
                style={{
                  margin: "5px 0",
                  fontSize: "0.9rem",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                Overall Performance Score
              </p>
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
              padding: "15px",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "10px",
            }}
          >
            <h2 style={{ margin: 0, fontSize: "1.5rem", fontWeight: "bold" }}>
              86%
            </h2>
            <p
              style={{
                margin: "10px 0 0",
                fontSize: "0.9rem",
                color: "rgba(255, 255, 255, 0.9)",
              }}
            >
              Your Lesson Planning Score increased by 25% from the last month.
              Pretty good performance!
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <button
              style={{
                background: "none",
                border: "none",
                color: "rgba(255, 255, 255, 0.8)",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              &#8592;
            </button>
            <div style={{ display: "flex", gap: "5px" }}>
              {[...Array(5)].map((_, index) => (
                <span
                  key={index}
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background:
                      index === 0
                        ? "rgba(255, 255, 255, 0.9)"
                        : "rgba(255, 255, 255, 0.5)",
                  }}
                ></span>
              ))}
            </div>
            <button
              style={{
                background: "none",
                border: "none",
                color: "rgba(255, 255, 255, 0.8)",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              &#8594;
            </button>
          </div> */}
        </Grid2>
      </Grid2>

      {/** Draggable Graph Grid layout */}
      <div style={{ margin: '20px 40px' }}>
        <GraphGridLayout />
        {/* <ResizableDraggableGrid /> */}
      </div>
      
    </>
  );
};
