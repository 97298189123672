import { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { PPTViewer } from "../components/PPTViewer";
import { PPTTiles } from "../components/PPTTiles";
import FullScreenIcon from "../assets/images/fullscreen.png";
import { FullScreenPPTViewer } from "../components/FullScreenPPTViewer";
import axios from "axios";
import { API_URL, getHeaders } from "../config";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { VideoSection } from "../components/VideoSection";
import AssessmentSection from "../components/AssessmentSection";
import { Warm } from "./Warm";
import { useScreenTime } from "../context/ScreenTimeContext";
import { PDFViewer } from "../components/PDFViewer";
import { Intranet } from "./Intranet";
import ModuleFeedback from "../components/ModuleFeedback";
import "../assets/styles/button.css";

const sections = ["PPT", "Video", "PDF", "Warmup"];

export const Home = () => {
  const [categories, setCategories] = useState([]);
  const [selectedPpt, setSelectedPpt] = useState("");
  const [selectedPPTIndex, setPPTIndex] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);
  const [items, setItems] = useState({});
  const [isFullScreen, setFullScreen] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const section = localStorage.getItem('section');

  const { startPPTTracking, stopPPTTracking  } = useScreenTime();

  const [selectedSection, setSelectedSection] = useState(section || "PPT");

  const [availableSections, setAvailableSections] = useState(sections);
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(0);

  useEffect(() => {
    console.log("selectedSection123",selectedSection)
  }, [selectedSection]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`${API_URL}/api/PPT?section=${section}`, {
          headers: getHeaders().headers,
        });
        setCategories(data?.data);
      } catch (e) {
        const { status } = e?.response;
        if (!status) return;
        if (status === 401 || status === 403) {
          handleLogout();
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (isUpdated) {
      (async () => {
        try {
          const { data } = await axios.get(`${API_URL}/api/PPT?section=${section}`);
          setCategories(data?.data);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (categories.length) {
      setUpdated(false);
      if (openCategory === null) {
        setOpenCategory(0);
      }
      if (selectedPPTIndex === null) {
        setPPTIndex(0);
      }
      if (!selectedPpt && categories[0]?.items?.length) {
        setSelectedPpt(categories[0].items[0]);
      }
    }
  }, [categories]);

  useEffect(() => {
    if (categories.length && openCategory >= 0 && selectedPPTIndex >= 0) {
      setSelectedPpt(categories[openCategory]?.items[selectedPPTIndex]);
    }
  }, [selectedPPTIndex, openCategory, categories]);

  useEffect(() => {
    let filteredData;
    if(selectedPpt) {
      console.log('selected>>>>>', selectedPpt);
      if(!selectedPpt.ppt) {
        filteredData = sections.filter((item) => item !== "PPT");
      } else {
        filteredData = sections;
      }
      setAvailableSections(filteredData);
      console.log('available', filteredData[0]);
      setSelectedSection(filteredData[0]);
    }
  }, [selectedPpt])

  useEffect(() => {
    if (selectedPpt) {
      
      const filteredCategory = categories[openCategory];
      const filterdItems = filteredCategory.items;
      setItems({ categoryName: filteredCategory.title, items: filterdItems });
    }
  }, [selectedPpt]);

  const handleItemClick = (pptItem, index) => {
    setSelectedPpt(pptItem);
    setPPTIndex(index);
  };

  const handleFullScreen = () => {
    const elem = document.documentElement;

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // Enter fullscreen mode
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isFullScreen) {
      // Exit fullscreen if Esc key is pressed
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    // Listen for fullscreen change event to update state
    const onFullScreenChange = () => {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        startPPTTracking();
        setFullScreen(true);
      } else {
        stopPPTTracking(selectedPpt.title);
        setFullScreen(false);
      }
    };

    // Add event listeners for fullscreen change and keydown (for Esc key)
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange); // Safari/Chrome
    document.addEventListener("mozfullscreenchange", onFullScreenChange); // Firefox
    document.addEventListener("MSFullscreenChange", onFullScreenChange); // IE/Edge
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
      document.removeEventListener("mozfullscreenchange", onFullScreenChange);
      document.removeEventListener("MSFullscreenChange", onFullScreenChange);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullScreen]);

  const selectPrevious = (index) => {
    if (index) {
      setSelectedPpt(categories[openCategory].items[index - 1]);
      setPPTIndex(index - 1);
    }
  };

  // const selectNext = (index) => {
  //   if (categories[openCategory].items.length > index + 1) {
  //     setSelectedPpt(categories[openCategory].items[index + 1]);
  //     setPPTIndex(index + 1);
  //   }
  // };

  const selectNext = (index) => {
    if (index + 1 === categories[openCategory].items.length) {
      setSelectedPpt(categories[openCategory].items[0]);
      setPPTIndex(0);
    } else {
      setSelectedPpt(categories[openCategory].items[index + 1]);
      setPPTIndex(index + 1);
    }
    // if (categories[openCategory].items.length > index + 1) {
    //   setSelectedPpt(categories[openCategory].items[index + 1]);
    //   setPPTIndex(index + 1);
    // }
  };

  const handleLogout = () => {
    console.log("logout click..");
    localStorage.removeItem("token");
    localStorage.removeItem("savedLayouts");
    navigate("/");
  };

  const handleSwitchModule = (categoryIndex, title) => {
    if(openCategory !== categoryIndex) {
      setCategoryIndex(categoryIndex);
      setFeedbackModalOpen(!isFeedbackModalOpen);
    }
  }

  const handleSwitch = () => {
    setOpenCategory(categoryIndex === openCategory ? null : categoryIndex);
    setFeedbackModalOpen(false);
    setSelectedSection("PPT");
  }

  return (
    <>
      { isFeedbackModalOpen && <ModuleFeedback open={isFeedbackModalOpen} show={setFeedbackModalOpen} handleAgree={handleSwitch} module={categories[openCategory]} /> }
      {isFullScreen && selectedSection === "PPT" ? (
        <FullScreenPPTViewer
          ppt={selectedPpt}
          // ppt={categories[openCategory].items[selectedPPTIndex]}
          handleFullScreen={handleFullScreen}
          isFullScreen={isFullScreen}
          setCategories={setCategories}
          selectedPPTIndex={selectedPPTIndex}
          selectedCategoryIndex={openCategory}
        />
      ) : (
        <div className="app-container">
          {loading ? (
            <h4>Loading..</h4>
          ) : (
            selectedSection !== "Intranet" &&
            <Sidebar
              categories={categories}
              onItemClick={handleItemClick}
              openCategory={openCategory}
              setOpenCategory={setOpenCategory}
              selectedPPT={selectedPpt}
              handleLogout={handleLogout}
              selectedSection={selectedSection}
              selectedPPTIndex={selectedPPTIndex}
              currentSection={setSelectedSection}
              handleSwitchModule={handleSwitchModule}
            />
          )}
          {/* {selectedSection === "Intranet" && 
            <Intranet />
          } */}
          {/* { !categories.length || selectedSection !== "Intranet"  && <h4 style={{ margin: '10px' }}>Modules in {section} will be added soon...</h4> } */}
          {!isFullScreen && selectedPpt && selectedSection === "PPT" && (
            <PPTViewer
              // ppt={categories[openCategory]?.items[selectedPPTIndex]}
              ppt={selectedPpt}
              items={items}
              selectNext={selectNext}
              selectPrevious={selectPrevious}
              pptIndex={selectedPPTIndex}
              onItemClick={handleItemClick}
              handleFullScreen={handleFullScreen}
              isFullScreen={isFullScreen}
              setUpdated={setUpdated}
              setCategories={setCategories}
              selectedPPTIndex={selectedPPTIndex}
              selectedCategoryIndex={openCategory}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
            />
          )}
          {selectedPpt && selectedSection === "Video" && (
            <VideoSection
              ppt={selectedPpt}
              items={items}
              // selectNext={selectNext}
              // selectPrevious={selectPrevious}
              pptIndex={selectedPPTIndex}
              onItemClick={handleItemClick}
              // handleFullScreen={handleFullScreen}
              // isFullScreen={isFullScreen}
              // setUpdated={setUpdated}
              // setCategories={setCategories}
              selectedPPTIndex={selectedPPTIndex}
              // selectedCategoryIndex={openCategory}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
            />
          )}
          {selectedPpt && selectedSection === "Warmup" && (
            <AssessmentSection selectedModule={selectedPpt} />
          )}
          {selectedSection === "generalWarmup" && <Warm />}
          {selectedPpt && selectedSection === "PDF" && (
            <PDFViewer pdf={selectedPpt.pdf} />
          )}
          <button className="feedback-button" onClick={handleSwitchModule}>Feedback</button>
        </div>
      )} 
      {!isFullScreen && selectedSection !== "generalWarmup" && (
        <div class="bottom-bar">
          {availableSections.map((section) =>
            section === "PDF" && selectedPpt?.pdf ? (
              <div
                className={
                  section === selectedSection
                    ? "selected-section"
                    : "section-item"
                }
                onClick={() => setSelectedSection(section)}
              >
                <p>PDF</p>
              </div>
            ) : (
              section !== "PDF" && (
                <div
                  className={
                    section === selectedSection
                      ? "selected-section"
                      : "section-item"
                  }
                  onClick={() => setSelectedSection(section)}
                >
                  <p>{section}</p>
                </div>
              )
            )
          )}
        </div>
      )}
    </>
  );
};
