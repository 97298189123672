import React, { Component } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "../assets/styles/react-grid.css";
import { UserModuleAnalysis } from "./UserModuleAnalysis";
import { UserModuleVideoAnalysis } from "./UserModuleVideoAnalysis";
import { UserAssessmentAnalysis } from "./UserAssessmentAnalysis";
import { UserwarmupAnalysis } from "./UserWarmupAnalysis";

const ResponsiveGridLayout = WidthProvider(Responsive);

function Header() {
  return <></>;
}

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: [
        { i: "1", x: 0, y: 0, w: 2, h: 1, minW: 2, minH: 1 },
        { i: "2", x: 2, y: 0, w: 2, h: 1, minW: 2, minH: 1 },
        { i: "3", x: 6, y: 0, w: 2, h: 1, minW: 2, minH: 1 },
        { i: "4", x: 8, y: 0, w: 2, h: 1, minW: 2, minH: 1 },
      ],
    };
  }

  render() {
    const components = [
      <UserModuleAnalysis />,
      <UserModuleVideoAnalysis />,
      <UserAssessmentAnalysis />,
      <UserwarmupAnalysis />,
    ];

    return (
      <ResponsiveGridLayout
        layouts={{ lg: this.state.layout }}
        measureBeforeMount={true}
        className="layout"
        isDraggable={true}
        isResizable={true}
        margin={[30, 30]}
      >
        {components.map((Component, index) => (
          <div key={index + 1} className="grid-item">
            <Header />
            {Component}
          </div>
        ))}
      </ResponsiveGridLayout>
    );
  }
}
